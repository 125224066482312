// custom typefaces
import "@fontsource/montserrat/variable.css"
import "@fontsource/merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
//import "prismjs/themes/prism.css"
//import 'prismjs/themes/prism-dark.css'
//import 'prismjs/themes/prism-twilight.css'
import 'prismjs/themes/prism-tomorrow.css'

export const onClientEntry = () => {
    const theme = window.localStorage.getItem("theme");
    if (theme) {
        if (theme === "dark") {
            document.body.classList.add("dark");
        }
    }
    else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.body.classList.add("dark");
    }
}